<template>
    <div class="col-6 col-xs-12 col-md-8">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="countries"
                        table-id="countriesTable"
                        style="padding-top: 2px !important;"
                        @add-action="editDialog = true"
                    ></ExportTable>
                    <HelpPopUpV2 help-page-component="SalesColleaguesListing" :no-padding="true"></HelpPopUpV2>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.salesColleagues"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allSalesColleagues"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            :sort-by="'Salesperson.name'"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="salesColleaguesTable"
            item-key="Salesperson.id"
        >
            <template v-slot:item.index="{ item }">
                <div class="text-center">{{ allSalesColleagues.indexOf(item) + 1}}</div>
            </template>
            <template v-slot:item.Salesperson.name="{ item }">
                <span class="font-weight-bold">{{ item.Salesperson.name }}</span>
            </template>
            <template v-slot:item.Salesperson.cm="{ item }">
                <span v-if="item.Salesperson.cm == 1">{{ $t('message.yes') }}</span>
                <span v-else>{{ $t('message.no') }}</span>
            </template>
            <template v-slot:item.Salesperson.cm_salescontact_ids="{ item }">
                <div v-for="id in item.Salesperson.cm_salescontact_ids">{{ allSalesColleagues.find(sc => sc.Salesperson.id == id)['Salesperson']['name'] }}</div>
            </template>
            <template v-slot:item.Salesperson.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditSalesColleagueDialog(item.Salesperson.id, item.Salesperson.name)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateSalesColleague') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteSalesColleague(item.Salesperson.id, item.Salesperson.name)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteSalesColleague') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <AddSalesColleague
            :sales-colleague-id="selectedSalesColleagueId"
            :sales-colleague-name="selectedSalesColleagueName"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="salesColleagueUpdated"
            @create-done="salesColleagueCreated"
        ></AddSalesColleague>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";

const AddSalesColleague = () => import("Components/Appic/AddSalesColleague");
const ExportTable = () => import("Components/Appic/ExportTable");
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "SalesColleaguesListing",
    components: { HelpPopUpV2, AddSalesColleague, ExportTable },
    props: ['addDialog','reload'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            filterOptions: {
                office: 1
            },
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                salesColleagues: false
            },
            searchField: null,
            searchTerm: null,
            selectedSalesColleagueId: null,
            selectedSalesColleagueName: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            totalSalesColleagues: 0,
            updateMode: false
        }
    },
    computed: {
        ...mapFields('salescolleague',{
            allSalesColleagues: 'allSalesColleagues'
        }),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Salesperson.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.name'),
                    value: 'Salesperson.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.email'),
                    value: 'Salesperson.userid',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.cm'),
                    value: 'Salesperson.cm',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.team'),
                    value: 'Salesperson.cm_salescontact_ids',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 6,
                    text: this.$t('message.phone'),
                    value: 'Salesperson.phone',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.skype'),
                    value: 'Salesperson.skype',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        }
    },
    methods: {
        ...mapActions('salescolleague',{
            deleteSalesColleagueById: 'deleteSalesColleagueById',
            getAllSalesColleagues: 'getAllSalesColleagues',
            resetState: 'resetState'
        }),
        async deleteSalesColleague(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteSalesColleague') + ' ' +  title, this.$t('message.confirmations.continueSalesColleagueCancelAction'), {color: 'orange'})){
                this.deleteSalesColleagueById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.salesColleagueDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.resetState()
                        this.getAllSalesColleagues()
                    } else {
                        this.$toast.error(this.$t('message.errors.salesColleagueNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateSalesColleagueDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditSalesColleagueDialog (salesColleagueId, salesColleagueName) {
            this.selectedSalesColleagueId = salesColleagueId
            this.selectedSalesColleagueName = salesColleagueName
            this.updateMode = true
            this.editDialog = true
        },
        salesColleagueCreated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        salesColleagueUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateSalesColleagueDialog ()
            }
        }
    },
    created() {
        if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>